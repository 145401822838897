<template>
  <a-spin :spinning="page_loading">
    <sdPageHeader title="Cargong Ödeme Listesi">
      <template #buttons>
        <div class="page-header-actions">
          <router-link :to="{ name: 'CargongAddCredit.Page' }">
            <sdButton
              size="small"
              type="primary"
            >
              <sdFeatherIcons
                type="send"
                size="14"
              />
              <span>Kontör Yükle</span>
            </sdButton>
          </router-link>
        </div>
      </template>
    </sdPageHeader>
    <Main>
      <sdCards headless>
        <a-row type="flex">
          <a-col :span="3">
            <a-form-item name="generalSearch">
              <a-input
                v-model:value="selected.text"
                placeholder="Arama Yap"
              />
            </a-form-item>
          </a-col>
          <a-col :span="3">
            <a-form-item name="generalSearch">
              <a-input
                v-model:value="selected.firstPrice"
                placeholder="Başlangıç Fiyat"
              />
            </a-form-item>
          </a-col>
          <a-col :span="3">
            <a-form-item name="generalSearch">
              <a-input
                v-model:value="selected.lastPrice"
                placeholder="Bitiş Fiyat"
              />
            </a-form-item>
          </a-col>
          <a-col :span="3">
            <a-form-item name="rangePicker">
              <a-range-picker
                :value="selected.date"
                format="DD/MM/YYYY"
                @change="selected.date = $event"
              />
            </a-form-item>
          </a-col>
          <a-col
            :span="3"
            style="text-align: center"
          >
            <a-form-item>
              <a-button
                style="width: 200%"
                type="primary"
                @click="get()"
              >
                Filtrele
              </a-button>
            </a-form-item>
          </a-col>
        </a-row>

        <a-table
          :data-source="data"
          :columns="columns"
          :pagination="pagination"
          row-key="id"
        >
          <template #is_processed_render="{ record }">
            <span v-if="record.is_processed===true">
              <i
                class="fa fa-check-square"
                aria-hidden="true"
                style="color:#34c45a ;text-shadow: 1px 1px 1px #ccc;font-size: 1.5em"
              />
            </span>
            <span v-else>
              <i
                class="fa fa-minus-square-o"
                aria-hidden="true"
                style="color: #bd8e2b;text-shadow: 1px 1px 1px #ccc;font-size: 1.5em"
              />
            </span>
          </template>
          <template #settings_activity_render="{ record }">
            <sdFeatherIcons
              type="settings"
              style="cursor:pointer"
              @click="handleSettingsModal(record.id)"
            />
          </template>
        </a-table>
      </sdCards>
    </Main>
  </a-spin>

  <a-modal
    v-model:visible="show_edit_settings"
    title="Bilgileri Düzenle"
    :confirm-loading="edit_settings_submitting"
    ok-text="Kaydet"
    cancel-text="Kapat"
    @ok="savepaymenHistoryListInvoiceInfo"
    @cancel="show_edit_settings = false"
  >
    <a-form layout="vertical">
      <a-form-item
        html-for="is_processed"
        label="İşlendi mi?"
      >
        <a-switch
          id="is_processed"
          v-model:checked="edit_settings_form.state.is_processed"
          checked-children="Evet"
          un-checked-children="Hayır"
        />
      </a-form-item>
      <a-form-item
        html-for="invoice_number"
        label="Fatura Numarası"
      >
        <a-input
          id="invoice_number"
          v-model:value="edit_settings_form.state.invoice_number"
        />
      </a-form-item>
      <a-form-item
        label="Muhasebe Notu"
        html-for="accounting_note"
      >
        <a-textarea
          id="accounting_note"
          v-model:value="edit_settings_form.state.accounting_note"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { Main } from './../styled'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    Main,
  },
  data() {
    return {
      edit_settings_submitting: false,

      data: [],
      page_loading: true,
      selected: {
        text: '',
        customer: '',
        firstPrice: '',
        lastPrice: '',
        date: '',
        provision: '',
        contracted: '',
      },

      show_edit_settings: false,

      edit_settings_form: {
        id: '',
        state: {
          is_processed: false,
          invoice_number: '',
          accounting_note: '',
        },
      },

      pagination: {
        total: 0,
        current: 1,
        pageSize: 30,
        showSizeChanger: true,
        pageSizeOptions: ['10', '30', '50', '100', '200'],
      },

      columns: [
        {
          title: 'Müşteri Adı',
          dataIndex: 'title',
        }, {
          title: 'Tarih',
          dataIndex: 'created_at',
        },
        {
          title: 'Toplam',
          dataIndex: 'total',
          align: 'right',
        },
        {
          title: 'İşlendi Mi?',
          dataIndex: 'is_processed',
          align: 'center',
          slots: {
            customRender: 'is_processed_render',
          },
        },
        {
          title: 'Fatura No',
          dataIndex: 'invoice_number',
          align: 'center',
        },
        {
          title: '',
          dataIndex: 'settings_activity',
          align: 'center',
          slots: {
            customRender: 'settings_activity_render',
          },
        },
      ],
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    savepaymenHistoryListInvoiceInfo() {
      this.edit_settings_submitting = true
      this.$restMethods.postForNoc('/cargong-payment-edit-submit/' + this.edit_settings_form.id, this.edit_settings_form.state).then(response => {
        if ( ! response.hasError()) {
          this.$message.success(response.getData().message)
          this.get()
          this.show_edit_settings = false
        }
        this.edit_settings_submitting = false
      })
    },
    handleSettingsModal(id) {
      this.page_loading = true
      this.$restMethods.postForNoc('/cargong-payment-edit-show/' + id).then(response => {
        if ( ! response.hasError()) {
          this.edit_settings_form.id = id
          this.edit_settings_form.state = response.getData().state

          this.show_edit_settings = true
        }
        this.page_loading = false
      })
    },
    get() {
      this.page_loading = true
      this.$restMethods.postForNoc('/cargong-payment-list', {
        filters: this.selected,
      }).then(response => {
        if ( ! response.hasError()) {
          const data = response.getData()
          this.data = data.items
        }
        this.page_loading = false
      })
    },
  },
})
</script>

<style>
.ant-layout-footer {
  margin-top: 350px
}

.ant-row {
  justify-content: center !important;;
}
</style>

